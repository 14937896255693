<template>
	<form class="form" @submit.stop.prevent="onSubmit">
		<div class="card card-custom">
			<div class="card-header border-0 py-5">
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.Administration.Organisation.title') }}</span>
				</h3>
			</div>
			<div class="card-body">
				<vue-loaders-square-spin v-if="pageProcesses.isLoading" color="#000"></vue-loaders-square-spin>
				<div class="row" v-if="!pageProcesses.isLoading">
					<div class="col-12">
						<div class="form-group">
							<label>{{ $t('GENERAL.Forms.name') }}</label>
							<input type="text" class="form-control" v-model="form.name" name="name" />
						</div>
					</div>
					<div class="col-12">
						<div class="form-group">
							<label for="description">{{ $t('GENERAL.Forms.description') }}</label>
							<textarea id="description" name="description" class="form-control" rows="5" v-model="form.description"></textarea>
						</div>
					</div>
<!--					<div class="col-12">-->
<!--						<hr />-->
<!--					</div>-->
<!--					<div class="col-12 prinor-table">-->
<!--						<b-table ref="textTable" :data="form.companies" :paginated="true" :per-page="5" default-sort="name" :default-sort-direction="'asc'" :sort-icon="'caret-up'">-->
<!--							<b-table-column field="name" :label="'Name'" v-slot="props" :sortable="true" :searchable="true" :cell-class="'d-flex'">-->
<!--								<template>-->
<!--									<span class="truncate">{{ props.row.name }}</span>-->
<!--								</template>-->
<!--							</b-table-column>-->
<!--						</b-table>-->
<!--					</div>-->
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { LOGOUT } from '@/core/services/store/auth.module';
import Swal from 'sweetalert2';
import {PRINOR_ACTIONS, PRINOR_PROCESSES} from '@/core/services/actions.service';
import { mapGetters } from 'vuex';
import {PRINOR_TOASTS} from "@/core/services/toast.service";
import {SET_ACTION} from "@/core/services/store/actions.module";
import {PRINOR_PERMISSION} from "@/core/services/permissions.service";

export default {
	name: 'Organisation',
	data() {
		return {
			organizationId: '',
			form: {
				name: '',
				description: '',
				companies: [],
			},
			saveButtonId: '',
			deleteButtonId: '',
		};
	},
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: this.$t('MENU.Administration.Administration'),
				route: '/administration',
			},
			{ title: this.$t('MENU.Administration.Organisation') },
		]);

		this.saveButtonId = PRINOR_ACTIONS.create(this, this.$t('GENERAL.Forms.save'), this.onSubmit, 'success', '', false, !this.hasPermission('action.organisation.save'));
		this.deleteButtonId = PRINOR_ACTIONS.create(this, this.$t('GENERAL.Forms.delete'), this.onDelete, 'danger', 'fas fa-exclamation-triangle', false, !this.hasPermission('action.organisation.delete'));

		PRINOR_PROCESSES.isLoading();

		this.get().then(data => {
			this.organizationId = data.data.id;
			this.form.name = data.data.name;
			this.form.description = data.data.description;
			this.form.companies = data.data.companies;
		});
	},
	computed: {
		...mapGetters(['pageProcesses']),
	},
	methods: {
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		onSubmit() {
			PRINOR_PROCESSES.isUpdating();
			this.update();
		},
		onDelete() {
			let that = this;
			Swal.fire({
				title: this.$t('VIEWS.Administration.Organisation.deleteTitle'),
				text: this.$t('VIEWS.Administration.Organisation.deleteConfirmation'),
				icon: 'warning',
				confirmButtonText: this.$t('GENERAL.Forms.delete'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				heightAuto: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					PRINOR_PROCESSES.isDeleting();
					that.delete().then(data => {
						if (data.success === true) {
							this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: 'login' }));
						}
					});
				}
			});
		},
		get() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('organization').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						resolve(data.data);
					}
				});
			});
		},
		update() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.put('organization/' + this.organizationId, this.form).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							PRINOR_TOASTS.save.confirmation(this);
						} else {
							PRINOR_TOASTS.save.error(this, data.data.error);
						}
						resolve(data.data);
					}
				});
			});
		},
		delete() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.delete('organization/' + this.organizationId).then(data => {
					PRINOR_PROCESSES.reset();
					resolve(data.data);
				});
			});
		},
	},
	watch: {
		pageProcesses: {
			deep: true,
			handler: function(newVal) {
				PRINOR_ACTIONS.setProcessing(this, this.saveButtonId, newVal.isUpdating);
				PRINOR_ACTIONS.setProcessing(this, this.deleteButtonId, newVal.isDeleting);
			},
		},
	},
};
</script>
